import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IconSpinner, ListingCard, NamedLink } from '../../components';
import { userDisplayNameAsString } from '../../util/data';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import { getAuthorListings } from './SectionOtherListings.duck';

import css from './SectionOtherListings.module.css';

export const SectionOtherListingsComponent = props => {
  const { author, intl, otherListings, isLoading, hasError, listingId, getAuthorListings } = props;
  useEffect(() => {
    getAuthorListings(author.id, listingId);
  }, [listingId]);
  const authorName = userDisplayNameAsString(author, '');
  const sectionTitle = intl.formatMessage(
    {
      id: 'SectionOtherListings.title',
    },
    { authorName }
  );

  const cardRenderSizes = () => {
    return [
      '(max-width: 549px) 100vw',
      '(max-width: 767px) 26vw',
      `(max-width: 1439px) 26vw`,
      `(max-width: 1920px) 26vw`,
      `14vw`,
    ].join(', ');
  };
  const hasOtherListings = otherListings.length > 0;
  const loading = isLoading ? <IconSpinner /> : null;
  const error =
    !isLoading && hasError ? (
      <p className={css.messageError}>
        <FormattedMessage id="SectionOtherListings.couldNotRetrieve" />
      </p>
    ) : null;

  const listingsToShow = otherListings.slice(0, 3);
  const body =
    !isLoading && hasOtherListings ? (
      <div className={css.contentWrapper}>
        <h2 className={css.sectionTitle}>{sectionTitle} </h2>
        <div className={css.listingCards}>
          {listingsToShow.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes()}
            />
          ))}
        </div>
        {otherListings.length > 3 ? (
          <NamedLink name="ProfilePage" params={{ id: author.id.uuid }}>
            <FormattedMessage id="SectionOtherListings.seeMore" values={{ authorName }} />
          </NamedLink>
        ) : null}
      </div>
    ) : null;

  return (
    <div>
      {loading}
      {body}
      {error}
    </div>
  );
};

const mapStateToProps = state => {
  const { isLoading, hasError, otherListings } = state.SectionOtherListings;

  return {
    isLoading,
    hasError,
    otherListings,
  };
};

const mapDispatchToProps = dispatch => ({
  getAuthorListings: (authorId, listingId) => dispatch(getAuthorListings(authorId, listingId)),
});

const SectionOtherListings = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SectionOtherListingsComponent);

export default SectionOtherListings;
