import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import config from '../../config';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import { formatMoney } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
  propTypes,
} from '../../util/types';
import {
  createSlug,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';

import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  Page,
  Button,
} from '../../components';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import ActionBarMaybe from './ActionBarMaybe';
import { fetchTransactionLineItems, sendEnquiry } from './ListingPage.duck';
import MessagePanel from './MessagePanel';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionGallery from './SectionGallery';
import SectionHeading from './SectionHeading';
import SectionMapMaybe from './SectionMapMaybe';

import DonationDetailsPanel from '../../components/OrderPanel/DonationDetailsPanel/DonationDetailsPanel';
import SectionCloseListing from './SectionCloseListing';
import SectionOtherListings from './SectionOtherListings';
import SectionRulesMaybe from './SectionRulesMaybe';

import css from './ListingPage.module.css';
import { openModal, openListing, closeListing, closeModal } from './SectionCloseListing.duck';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const getCategoryAndSubcategory = (publicData, customConfig) => {
  const { listing, filters } = customConfig || {};
  if (!publicData || !customConfig || !listing?.enumFieldDetails) {
    return {};
  }

  const category = filters[0]?.config?.options?.find(o => o.key === publicData.category);
  const subcategory = category?.subcategories?.find(o => o.key === publicData.subcategory);

  return { category: category?.label, subcategory: subcategory?.label };
};

const getSuburbAndState = search => {
  if (search) {
    const addressParts = search.split(', ');

    switch (addressParts.length) {
      case 3:
        return `${addressParts[0]}, ${addressParts[1]}`;
      case 4:
        return `${addressParts[1]}, ${addressParts[2]}`;
      default:
        break;
    }
  }

  // This should never happen because location is required
  return '';
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
    };
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, quantity: quantityRaw, deliveryMethod, ...otherOrderData } = values;
    const bookingDatesMaybe = bookingDates
      ? {
          bookingDates: {
            bookingStart: bookingDates.startDate,
            bookingEnd: bookingDates.endDate,
          },
        }
      : {};

    const initialValues = {
      listing,
      orderData: {
        ...bookingDatesMaybe,
        quantity: Number.parseInt(quantityRaw, 10),
        deliveryMethod,
        ...otherOrderData,
      },
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  render() {
    const {
      unitType,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      customConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      isModalOpen,
      hasError,
      submitInProgress,
      onCloseListing,
      onCloseModal,
    } = this.props;

    const listingId = new UUID(rawParams.id);

    const onActivate = () => {
      this.props.onOpenListing(listingId);
    };
    const onCloseListingButtonClicked = () => {
      this.props.onOpenModal(listingId);
    };

    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    const isClosed = currentListing.id && currentListing.attributes.state === LISTING_STATE_CLOSED;
    const isPublished =
      currentListing.id && currentListing.attributes.state === LISTING_STATE_PUBLISHED;

    const { deliveryOptions, suburb, conditions } = currentListing?.attributes?.publicData || {};

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location?.search} />;
    }

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title: richTitle }} />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    const showCloseListingButton = isOwnListing && isPublished;
    const showReactivateButton = isOwnListing && isClosed;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleOrderSubmit = values => {
      if (isOwnListing || isClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = listingImages(currentListing, `${config.listing.variantPrefix}-2x`).map(
      img => img.url
    );

    const getPageDescription = () => {
      const { category, subcategory } = getCategoryAndSubcategory(publicData, customConfig);
      const suburbAndState = getSuburbAndState(suburb?.search);

      if (category && subcategory) {
        return intl.formatMessage(
          { id: 'ListingPage.schemaDescription' },
          { category, subcategory, suburbAndState }
        );
      }

      // Miscellaneous category
      return intl.formatMessage({ id: 'ListingPage.defaultSchemaDescription' }, { suburbAndState });
    };

    const schemaDescription = getPageDescription();

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ListingPage.schemaTitle' }, { title, siteTitle });

    // You could add reviews, sku, etc. into page schema
    // Read more about product schema
    // https://developers.google.com/search/docs/advanced/structured-data/product
    const productURL = `${config.canonicalRootURL}${location.pathname}${location.search}${location.hash}`;
    const brand = currentListing?.attributes?.publicData?.brand;
    const brandMaybe = brand ? { brand: { '@type': 'Brand', name: brand } } : {};
    const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
    const schemaAvailability =
      currentStock > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock';

    const authorLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#author' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const amenityOptions = findOptionsForSelectFilter('amenities', customConfig.filters);

    const userRole = currentUser?.attributes.profile.metadata?.roles ?? [];
    const showMessageFrame =
      !currentUser || (userRole.includes('COMMUNITY_PARTNER') && !isOwnListing);

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={schemaDescription}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          description: schemaDescription,
          name: schemaTitle,
          image: schemaImages,
          ...brandMaybe,
          offers: {
            '@type': 'Offer',
            url: productURL,
            availability: schemaAvailability,
            priceCurrency: 'AUD',
            price: 0,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.wrapperForProductAndMessage}>
                <div className={css.mainColumnForProductLayout}>
                  {currentListing.id ? (
                    <ActionBarMaybe
                      className={css.actionBarForProductLayout}
                      isOwnListing={isOwnListing}
                      listing={currentListing}
                      editParams={{
                        id: listingId.uuid,
                        slug: listingSlug,
                        type: listingType,
                        tab: 'details',
                      }}
                    />
                  ) : null}
                  <SectionGallery listing={currentListing} />
                  <div className={css.productMobileHeading}>
                    <SectionHeading
                      priceTitle={priceTitle}
                      formattedPrice={formattedPrice}
                      richTitle={richTitle}
                      authorLink={authorLink}
                      detailsPanel={
                        <DonationDetailsPanel
                          condition={conditions}
                          currentStock={currentStock}
                          selectedDeliveryOptions={deliveryOptions}
                          location={suburb?.search}
                        />
                      }
                    />
                  </div>
                  <SectionDescriptionMaybe description={description} listingTitle={richTitle} />
                  <SectionDetailsMaybe publicData={publicData} customConfig={customConfig} />
                  <SectionFeaturesMaybe
                    extendedDataKey="amenities"
                    options={amenityOptions}
                    publicData={publicData}
                  />
                  <SectionRulesMaybe publicData={publicData} />
                  {authorAvailable && (
                    <SectionOtherListings author={ensuredAuthor} listingId={currentListing.id} />
                  )}
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                </div>
                <div className={css.orderColumnForProductLayout}>
                  <div className={css.productOrderPanel}>
                    <OrderPanel
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      unitType={unitType}
                      onSubmit={handleOrderSubmit}
                      title={bookingTitle}
                      author={ensuredAuthor}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onContactUser={this.onContactUser}
                      timeSlots={timeSlots}
                      fetchTimeSlotsError={fetchTimeSlotsError}
                      onFetchTransactionLineItems={onFetchTransactionLineItems}
                      lineItems={lineItems}
                      fetchLineItemsInProgress={fetchLineItemsInProgress}
                      fetchLineItemsError={fetchLineItemsError}
                    />
                    {showMessageFrame && (
                      <MessagePanel
                        className={css.productOrderPanel}
                        author={authorDisplayName}
                        listingId={listingId}
                        onSendEnquiry={sendEnquiry}
                        sendEnquiryError={sendEnquiryError}
                        sendEnquiryInProgress={sendEnquiryInProgress}
                        onManageDisableScrolling={onManageDisableScrolling}
                      />
                    )}
                    <div className={`${css.footer} ${!isOwnListing && css.hideElement}`}>
                      {showCloseListingButton && (
                        <Button className={css.button} onClick={onCloseListingButtonClicked}>
                          <FormattedMessage id="SectionCloseListing.closeListing" />
                        </Button>
                      )}
                      {showReactivateButton && (
                        <>
                          <Button
                            className={css.button}
                            onClick={onActivate}
                            inProgress={this.props.submitInProgress}
                            disabled={this.props.submitInProgress}
                          >
                            <FormattedMessage id="SectionCloseListing.reactivateListing" />
                          </Button>
                          {hasError && (
                            <p className={css.activateError}>
                              <FormattedMessage id="SectionCloseListing.somethingWentWrong" />
                            </p>
                          )}
                        </>
                      )}
                    </div>

                    {isModalOpen && (
                      <SectionCloseListing
                        isModalOpen={isModalOpen}
                        listingId={listingId}
                        onCloseModal={onCloseModal}
                        hasError={hasError}
                        submitInProgress={submitInProgress}
                        onCloseListing={onCloseListing}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  showListingError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  customConfig: config.custom,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryError: propTypes.error,
  onInitializeCardPaymentData: func.isRequired,
  customConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  } = state.ListingPage;
  const { currentUser } = state.user;
  const { isModalOpen, hasError, submitInProgress } = state.SectionCloseListing;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
    timeSlots,
    fetchTimeSlotsError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryError,
    isModalOpen,
    hasError,
    submitInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (orderData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(orderData, listingId, isOwnListing)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onOpenModal: () => dispatch(openModal()),
  onOpenListing: id => dispatch(openListing(id)),
  onCloseListing: (id, closedDetails, otherListingIdsToClose) =>
    dispatch(closeListing(id, closedDetails, otherListingIdsToClose)),
  onCloseModal: () => dispatch(closeModal()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
