export const USER_ROLES = {
  COMMUNITY_PARTNER: 'COMMUNITY_PARTNER',
  DONOR: 'DONOR',
};

export const REGISTRATING_STEPS = {
  DONOR_DETAILS: 'donor_details',
  CP_INFORMATION_AND_FORM_DOWNLOADING: 'cp_information_and_registerating_form_downloading',
  CP_THANKS_FOR_DOWNLOADING: 'cp_thanks_for_downloading',
};
