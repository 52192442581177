import { arrayOf, bool, func, number, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import isEqual from 'lodash/isEqual';
import { propTypes } from '../../util/types';
import { ModalInMobile } from '../../components';

import { ensureTransaction, ensureUser } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import SendMessageForm from '../TransactionPage/SendMessageForm/SendMessageForm';
import FeedSection from '../TransactionPage/TransactionPanel/FeedSection';

import { getTransaction, sendMessage } from './MessagePanel.duck';

import { IconSpinner, NamedLink, Button } from '../../components';
import { fetchMoreMessages } from '../TransactionPage/TransactionPage.duck';
import css from './MessagePanel.module.css';
import { REGISTRATING_STEPS, USER_ROLES } from '../AuthenticationPage/constants';
import Tooltip from '../../components/Tooltip/Tooltip';
import ThingsToRemember from '../EditListingPage/EditListingWizard/ThingsToRemember';

const { COMMUNITY_PARTNER } = USER_ROLES;
const { CP_INFORMATION_AND_FORM_DOWNLOADING } = REGISTRATING_STEPS;

export class MessagePanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      openMessageModal: false,
    };
    this.sendMessageFormName = 'MessagePanel.SendMessageForm';
  }

  componentDidMount() {
    this.props.getTransaction(this.props.currentUser?.id, this.props.listingId);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.listingId, this.props.listingId)) {
      this.props.getTransaction(this.props.currentUser?.id, this.props.listingId);
    }
  }

  onSendMessageFormFocus = () => {
    this.setState({ sendMessageFormFocused: true });
  };

  onSendMessageFormBlur = () => {
    this.setState({ sendMessageFormFocused: false });
  };

  onMessageSubmit = async (values, form) => {
    const message = values.message ? values.message.trim() : null;
    await this.props.onSendMessage(this.props.listingId, message);
    form.reset();
  };

  render() {
    const {
      currentUser,
      fetchMessagesError,
      fetchMessagesInProgress,
      initialMessageFailed,
      messages,
      oldestMessagePageFetched,
      onShowMoreMessages,
      totalMessagePages,
      sendMessageInProgress,
      sendMessageError,
      author,
      transaction,
      isLoading,
      intl,
      getTransactionError,
      sendEnquiryError,
      sendEnquiryInProgress,
      onManageDisableScrolling,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const currentProvider = ensureUser(currentTransaction.provider);

    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const isUserLoggedIn = !!currentUser;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'MessagePanel.sendMessagePlaceholder' },
      { name: author }
    );
    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'MessagePanel.sendingMessageNotAllowed',
    });
    const messageDonorToolTip = intl.formatMessage({
      id: 'MessagePanel.messageDonorToolTip',
    });

    const MODAL_BREAKPOINT = 1023;

    const messageFrame = (
      <div>
        <ModalInMobile
          id="MessageFrameModal"
          isModalOpenOnMobile={this.state.openMessageModal}
          onClose={() => this.setState({ openMessageModal: false })}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.messageFrameContainer}>
            <div className={css.contactDonorContainer}>
              <h3 className={css.contactDonor}>
                <FormattedMessage id="MessagePanel.heading" />
              </h3>

              <Tooltip
                className={css.tooltip}
                tooltipText={messageDonorToolTip}
                tooltipTextClassName={css.tooltipTextTop}
              />
            </div>

            <div className={css.mobileMessageDonorTip}>{messageDonorToolTip}</div>

            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={() => {}}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
            />

            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendEnquiryInProgress={sendEnquiryInProgress}
                sendMessageError={sendMessageError}
                sendEnquiryError={sendEnquiryError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}
          </div>
        </ModalInMobile>
        {!this.state.openMessageModal && (
          <div className={`${css.footer} ${css.messageDonor}`}>
            <Button onClick={() => this.setState({ openMessageModal: true })}>
              <FormattedMessage id="SendMessageForm.message" />
            </Button>
          </div>
        )}
      </div>
    );

    const loading = isLoading ? <IconSpinner /> : null;
    const body =
      !isLoading && !getTransactionError ? (
        !isUserLoggedIn ? (
          <div className={css.footer}>
            <h3 className={css.contactDonor}>
              <FormattedMessage id="MessagePanel.heading" />
            </h3>
            <NamedLink
              className={css.signUpButton}
              name="SignupPage"
              to={{
                state: {
                  registerFlow: {
                    role: COMMUNITY_PARTNER,
                    step: CP_INFORMATION_AND_FORM_DOWNLOADING,
                  },
                },
              }}
            >
              <FormattedMessage id="SendMessageForm.signUp" />
            </NamedLink>
          </div>
        ) : (
          messageFrame
        )
      ) : null;

    const error =
      !isLoading && getTransactionError ? (
        <p className={css.messageError}>
          <FormattedMessage id="MessagePanel.couldNotRetrieve" />
        </p>
      ) : null;

    return (
      <div className={css.messageContainer}>
        {loading}
        {error}
        {body}
      </div>
    );
  }
}

MessagePanelComponent.defaultProps = {
  currentUser: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  fetchMessagesError: null,
  sendMessageError: null,
  sendEnquiryError: null,
};

MessagePanelComponent.propTypes = {
  currentUser: propTypes.currentUser,
  fetchMessagesError: propTypes.error,
  fetchMessagesInProgress: bool.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  oldestMessagePageFetched: number.isRequired,
  onShowMoreMessages: func.isRequired,
  totalMessagePages: number.isRequired,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  intl: intlShape,
  author: string.isRequired,
  currentListing: propTypes.uuid,
  sendEnquiryError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
};

const mapStateToProps = state => {
  const { submitInProgress, getTransactionError, transaction, isLoading } = state.MessagePanel;
  const {
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    sendMessageInProgress,
    sendMessageError,
  } = state.TransactionPage;
  const { currentUser } = state.user;
  const { sendEnquiryInProgress } = state.ListingPage;

  return {
    currentUser,
    fetchMessagesInProgress,
    fetchMessagesError,
    totalMessagePages,
    oldestMessagePageFetched,
    messages,
    initialMessageFailedToTransaction,
    sendMessageInProgress,
    sendEnquiryInProgress,
    sendMessageError,
    getTransactionError,
    submitInProgress,
    transaction,
    isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onShowMoreMessages: txId => dispatch(fetchMoreMessages(txId)),
    getTransaction: (customerId, listingId) => dispatch(getTransaction(customerId, listingId)),
    onSendMessage: (listingId, message) => dispatch(sendMessage(listingId, message)),
  };
};

const MessagePanel = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MessagePanelComponent);

export default MessagePanel;
